import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'reactstrap';

import './toggle-button-base.scss';

export function ToggleButtonBase({
  children,
  value,
  onClick,
  className,
  isActive,
  activeClassName,
  inactiveClassName,
  activeButtonAdornment,
  tag,
  ...restButtonProps
}) {
  return (
    <Button
      onClick={onClick}
      className={classnames(
        'toggle-button-base toggle-button-control text-center border-0 text-transform-none',
        className,
        {
          [activeClassName || 'active-state bg-white text-cool-gray-30 fw-medium']: isActive,
          [inactiveClassName || 'inactive-state bg-cool-gray-70 text-cool-gray-40 fw-normal']: !isActive,
        }
      )}
      value={value}
      tag={tag}
      {...restButtonProps}
    >
      {activeButtonAdornment && isActive && activeButtonAdornment}
      {children || value}
    </Button>
  );
}

ToggleButtonBase.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.elementType,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  activeButtonAdornment: PropTypes.element,
  activeClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  className: PropTypes.string,
};

ToggleButtonBase.defaultProps = {
  children: null,
  isActive: false,
  tag: undefined,
  activeButtonAdornment: <i className="icon-checkmark size-10 text-blue-30 me-0_25" aria-hidden />,
  activeClassName: null,
  inactiveClassName: null,
  className: 'size-14 px-0_25',
  onClick: null,
};
