import React, { useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import { VIDEO_BACKGROUND_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import TAPERED_CONTENT_BOX from 'site-modules/shared/components/clipped-container/mask-library/tapered-content-box';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { randomInt } from 'client/utils/seed-randomizers';
import { TrackingConstant } from 'client/tracking/constant';
import { ClippedContainer } from 'client/site-modules/shared/components/clipped-container/clipped-container';
import { DealerCenterButton } from 'site-modules/microsite/dealers/components/dealer-center-button/dealer-center-button';
import { DealerCenterMedia } from 'site-modules/microsite/dealers/components/dealer-center-media/dealer-center-media';
import { DealerCenterVideoControlButton } from 'site-modules/microsite/dealers/components/dealer-center-video-control-button/dealer-center-video-control-button';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';

import './dealer-center-video-background.scss';

const VIDEO_BACKGROUND = {
  name: 'video-background',
  path:
    'M0.048,0.975 C0.032,0.977,0.016,0.978,0,0.98 V0.97 V0.03 V0.02 C0.017,0.022,0.034,0.023,0.05,0.025 C0.059,0.024,0.067,0.023,0.075,0.023 C0.423,-0.008,0.634,-0.008,0.952,0.025 C0.983,0.022,1,0.02,1,0.02 V0.03 V0.97 V0.98 C1,0.98,0.983,0.978,0.952,0.975 C0.626,1,0.413,1,0.05,0.975 C0.05,0.975,0.049,0.975,0.048,0.975',
};

const CONCAVE_CONTAINER = {
  name: 'concave-container',
  path: 'M0,0 v0.135 v0.712 V1 c0.514,-0.047,1,0,1,0 v-0.153 V0.135 V0 C1,0,0.514,0.047,0,0',
};

export function DealerCenterVideoBackgroundUI({ entry, isMobile }) {
  const containerRef = useRef(null);
  const videoRef = useRef();
  const uniqIdRef = useRef(randomInt());
  const getInnerMask = useCallback(
    maskObject => ({
      ...maskObject,
      name: `${maskObject.name}-${uniqIdRef.current}`,
    }),
    []
  );

  // Memoizing the object passed to the innerMask prop to prevent ClippedContainer instances from being re-rendered.
  const uniqVideoBackgroundMask = useMemo(() => getInnerMask(VIDEO_BACKGROUND), [getInnerMask]);
  const uniqConcaveContainerMask = useMemo(() => getInnerMask(CONCAVE_CONTAINER), [getInnerMask]);
  const uniqTaperedContentBoxMask = useMemo(() => getInnerMask(TAPERED_CONTENT_BOX), [getInnerMask]);

  const metadata = entry.getAllMetadata();

  const {
    headerText,
    subheaderText,
    regularText,
    ctaDisplay,
    ctaText,
    ctaURL,
    ctaNewTab,
    ctaColor,
    bkgImageDesktop,
    bkgImageMobile,
    bkgVideo,
  } = metadata;

  const shouldRender = !!headerText;

  useScrollIntoViewTracking({
    eventData: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.SUBACTION_VIDEO_BACKGROUND_MODULE_DISPLAYED,
        creative_id: VIDEO_BACKGROUND_CREATIVE_ID,
        value: headerText,
      },
    },
    additionalConditions: shouldRender,
    ref: containerRef,
  });

  if (!shouldRender) return null;

  return (
    <div
      className="video-background pos-r text-start my-3"
      data-tracking-parent={VIDEO_BACKGROUND_CREATIVE_ID}
      ref={containerRef}
    >
      <ClippedContainer mask={uniqVideoBackgroundMask} containerClasses="w-100 h-100">
        <div className="media-container">
          <DealerCenterMedia
            videoSrc={bkgVideo}
            imageSrc={isMobile ? bkgImageMobile : bkgImageDesktop}
            videoClass="background-video w-100 h-100 pos-a"
            imageClass="background-image w-100 h-100 pos-a"
            videoRef={videoRef}
          />
        </div>
      </ClippedContainer>
      <ClippedContainer mask={uniqConcaveContainerMask} containerClasses="concave-background-layer w-100 pos-a">
        <Container className="mt-3_5">
          <ClippedContainer
            containerClasses="text-container d-flex flex-column p-1_75 pe-3_5"
            mask={uniqTaperedContentBoxMask}
          >
            <h2 className="size-30 fw-bold">{headerText}</h2>
            {subheaderText && <span className="size-20 fw-medium">{subheaderText}</span>}
            {regularText && <p className="size-20 mt-1_25 mb-0">{regularText}</p>}
            {ctaDisplay === 'true' && (
              <DealerCenterButton
                href={ctaURL}
                color={ctaColor}
                className="mt-1_5 fw-medium"
                data-tracking-id="submit"
                data-tracking-value={ctaText}
                ctaText={ctaText}
                target={ctaNewTab === 'true' ? '_blank' : '_self'}
              />
            )}
          </ClippedContainer>
        </Container>
      </ClippedContainer>
      {bkgVideo && <DealerCenterVideoControlButton videoRef={videoRef} />}
    </div>
  );
}

DealerCenterVideoBackgroundUI.propTypes = {
  entry: CmsEntities.Content,
  isMobile: PropTypes.bool,
};

DealerCenterVideoBackgroundUI.defaultProps = {
  entry: DEFAULT_CONTENT,
  isMobile: false,
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DealerCenterVideoBackground = connect(mapStateToProps)(DealerCenterVideoBackgroundUI);
