import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import { get, noop } from 'lodash';
/* Utils */
import { getQuery } from 'client/utils/location';
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { EventToolbox } from 'client/utils/event-toolbox';
/* HOC */
import { formValidation } from 'site-modules/shared/components/form-validation/form-validation';
/* Constants */
import { PHONE_MASK_V2 } from 'site-modules/shared/constants/lead-form/validation';
import { DIGITAL_CHECKOUT_EVENTS } from 'site-modules/shared/constants/financing/digital-checkout-events';
/* Components */
import { FormField } from 'site-modules/shared/components/form-field/form-field';
import { FormFieldMaskV2 } from 'site-modules/shared/components/form-field-mask/form-field-mask-v2';
import { FormErrorMessage } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/form-error-message/form-error-message';
import { ActivationFormCheckbox } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-checkbox/activation-form-checkbox';
import { ActivationFormButton } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-button/activation-form-button';
import { DEACTIVATE_MODE, INVALID_MODE, UPDATE_MODE } from 'site-modules/microsite/dealers/constants/digital-checkout';
import { DeactivationFormView } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/deactivation-form-view/deactivation-form-view';

export const FORM_FIELDS = {
  firstName: 'dealerContactFirstName',
  lastName: 'dealerContactLastName',
  dealerName: 'dealerName',
  phoneNumber: 'dealerPhone',
  zipCode: 'dealerZipCode',
  email: 'dealerContactEmail',
};

const validators = {
  [FORM_FIELDS.firstName]: {
    test: validation.validateName,
    errorText: 'Please correct your name. Special characters cannot be included.',
  },
  [FORM_FIELDS.lastName]: {
    test: validation.validateName,
    errorText: 'Please correct your name. Special characters cannot be included.',
  },
  [FORM_FIELDS.dealerName]: {
    test: field => validation.validateMinLength(field, 4),
    errorText: 'Please correct your dealer name.',
  },
  [FORM_FIELDS.phoneNumber]: {
    test: validation.validatePhoneNumber,
    errorText: 'Please enter a valid phone number.',
  },
  [FORM_FIELDS.zipCode]: {
    test: validation.validateZip,
    errorText: 'Please enter a valid ZIP code.',
  },
  [FORM_FIELDS.email]: {
    test: validation.validateEmail,
    errorText: 'Please enter a valid email.',
  },
};

export function ActivationFormWrapperUI({
  validationErrors,
  validate,
  fields,
  resetFieldValidation,
  fieldRef,
  dealer,
  location,
  dataTrackingParent,
  submitCallback,
  metadata,
  mode,
}) {
  const rooftopId = get(getQuery(location), 'rooftopId');
  const dealerNameValid = !('dealerName' in validationErrors);
  const firstNameValid = !('dealerContactFirstName' in validationErrors);
  const lastNameValid = !('dealerContactLastName' in validationErrors);
  const emailValid = !('dealerContactEmail' in validationErrors);
  const phoneValid = !('dealerPhone' in validationErrors);
  const zipCodeValid = !('dealerZipCode' in validationErrors);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckboxInvalid, setIsCheckboxInvalid] = useState(false);
  const isUpdateMode = mode === UPDATE_MODE;
  const isDeactivateMode = mode === DEACTIVATE_MODE;
  const isInvalidMode = mode === INVALID_MODE;

  const { ctaText, checkboxContent, privacyLink, privacyLinkText } = metadata;

  const handleBlur = (e, name) => {
    const isValidated = validate(e, name);

    EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.INPUT_VALIDATION, {
      target: e.target,
      data: {
        isValidated,
        name,
      },
      creativeId: dataTrackingParent,
      rooftopId,
    });
  };

  const onSubmit = e => {
    e.preventDefault();

    let isValid;

    if (isUpdateMode) isValid = validate();
    else if (isDeactivateMode) {
      isValid = isChecked;
      setIsCheckboxInvalid(!isChecked);
    } else {
      isValid = validate() && isChecked;
      setIsCheckboxInvalid(!isChecked);
    }

    if (isValid) {
      const fieldsValues = !isDeactivateMode
        ? Object.keys(fields).reduce((prev, cur) => {
            // eslint-disable-next-line no-param-reassign
            prev[cur] = fields[cur].value();
            return prev;
          }, {})
        : {};
      submitCallback(fieldsValues);
    }
  };

  const zipCode = get(dealer, 'dealerZipCode');
  const dealerName = get(dealer, 'dealerName');

  const activationFormCheckbox = (
    <ActivationFormCheckbox
      setIsChecked={setIsChecked}
      isChecked={isChecked}
      copy={checkboxContent}
      privacyLink={privacyLink}
      privacyLinkText={privacyLinkText}
      contentClassNames={isDeactivateMode ? 'ms-1_75 size-20 text-cool-gray-10' : 'ms-2_5 size-14 text-cool-gray-30'}
      classNames={isDeactivateMode ? 'size-16' : 'size-24'}
      dataTrackingId="dealer_enrollment_consent"
      isError={isCheckboxInvalid}
      setIsError={setIsCheckboxInvalid}
    />
  );

  return (
    <Form noValidate data-tracking-parent={dataTrackingParent} onSubmit={onSubmit}>
      {isDeactivateMode ? (
        <DeactivationFormView dealer={dealer}>{activationFormCheckbox}</DeactivationFormView>
      ) : (
        <>
          <FormGroup className="my-0 mx-auto">
            <FormField
              id={FORM_FIELDS.dealerName}
              name={FORM_FIELDS.dealerName}
              label="Dealer Name"
              type="text"
              maxLength={255}
              className={classNames('pos-r', { 'mb-0': !dealerNameValid })}
              inputClassName="size-16"
              labelClassName="size-12 p-0"
              ref={field => fieldRef(FORM_FIELDS.dealerName, field)}
              isValid={dealerNameValid}
              aria-required
              aria-invalid={!dealerNameValid}
              aria-describedby={`${FORM_FIELDS.dealerName}-errorMessage`}
              aria-label="Dealer name"
              onBlur={validate}
              onChange={resetFieldValidation}
              defaultValue={dealerName}
              resetTouchOnChange
              {...dealerName && { readOnly: true }}
            />

            <FormErrorMessage
              id={`${FORM_FIELDS.dealerName}-errorMessage`}
              message={validationErrors[FORM_FIELDS.dealerName]}
            />
            <Row>
              <Col xs={6}>
                <FormField
                  id={FORM_FIELDS.firstName}
                  name={FORM_FIELDS.firstName}
                  label="First Name"
                  type="text"
                  maxLength={255}
                  className={classNames('pos-r', { 'mb-0': !firstNameValid })}
                  inputClassName="size-16"
                  labelClassName="size-12 p-0"
                  ref={field => fieldRef(FORM_FIELDS.firstName, field)}
                  isValid={firstNameValid}
                  onBlur={handleBlur}
                  aria-required
                  aria-invalid={!firstNameValid}
                  aria-describedby={`${FORM_FIELDS.firstName}-errorMessage`}
                  aria-label="First name"
                  onChange={resetFieldValidation}
                  resetTouchOnChange
                  autoComplete="given-name"
                />
                <FormErrorMessage
                  id={`${FORM_FIELDS.firstName}-errorMessage`}
                  message={validationErrors[FORM_FIELDS.firstName]}
                />
              </Col>
              <Col xs={6}>
                <FormField
                  id={FORM_FIELDS.lastName}
                  name={FORM_FIELDS.lastName}
                  label="Last Name"
                  type="text"
                  maxLength={255}
                  className={classNames('pos-r', { 'mb-0': !lastNameValid })}
                  inputClassName="size-16"
                  labelClassName="size-12 p-0"
                  ref={field => fieldRef(FORM_FIELDS.lastName, field)}
                  isValid={lastNameValid}
                  onBlur={handleBlur}
                  aria-required
                  aria-invalid={!lastNameValid}
                  aria-describedby={`${FORM_FIELDS.lastName}-errorMessage`}
                  aria-label="Last name"
                  onChange={resetFieldValidation}
                  resetTouchOnChange
                  autoComplete="family-name"
                />
                <FormErrorMessage
                  id={`${FORM_FIELDS.lastName}-errorMessage`}
                  message={validationErrors[FORM_FIELDS.lastName]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <FormFieldMaskV2
                  id={FORM_FIELDS.phoneNumber}
                  name={FORM_FIELDS.phoneNumber}
                  label="Phone"
                  aria-required
                  aria-invalid={!phoneValid}
                  aria-describedby={`${FORM_FIELDS.phoneNumber}-errorMessage`}
                  aria-label="Phone number"
                  isValid={phoneValid}
                  onBlur={handleBlur}
                  type="tel"
                  mask={PHONE_MASK_V2}
                  className="mb-0 pos-r"
                  inputClassName="size-16"
                  labelClassName="size-12 p-0"
                  onChange={resetFieldValidation}
                  resetTouchOnChange
                  ref={field => fieldRef(FORM_FIELDS.phoneNumber, field)}
                  autoComplete="tel"
                />
                <FormErrorMessage
                  id={`${FORM_FIELDS.phoneNumber}-errorMessage`}
                  message={validationErrors[FORM_FIELDS.phoneNumber]}
                />
              </Col>
              <Col xs={6}>
                <FormField
                  id={FORM_FIELDS.zipCode}
                  name={FORM_FIELDS.zipCode}
                  label="Dealer ZIP"
                  type="text"
                  maxLength={10}
                  className={classNames('pos-r', { 'mb-0': !zipCodeValid })}
                  inputClassName="size-16"
                  labelClassName="size-12 p-0"
                  ref={field => fieldRef(FORM_FIELDS.zipCode, field)}
                  isValid={zipCodeValid}
                  aria-required
                  aria-invalid={!zipCodeValid}
                  aria-describedby={`${FORM_FIELDS.zipCode}-errorMessage`}
                  aria-label="Dealer ZIP"
                  onBlur={validate}
                  onChange={resetFieldValidation}
                  defaultValue={zipCode}
                  resetTouchOnChange
                  {...zipCode && { readOnly: true }}
                />
                <FormErrorMessage
                  id={`${FORM_FIELDS.zipCode}-errorMessage`}
                  message={validationErrors[FORM_FIELDS.zipCode]}
                />
              </Col>
            </Row>
            <FormField
              id={FORM_FIELDS.email}
              name={FORM_FIELDS.email}
              label={isInvalidMode ? 'Contact Email' : 'Preferred Email for Receiving Offers'}
              maxLength={80}
              aria-required
              aria-invalid={!emailValid}
              aria-describedby={`${FORM_FIELDS.email}-errorMessage`}
              aria-label="Preferred Email for Receiving Offers"
              isValid={emailValid}
              onBlur={handleBlur}
              type="email"
              className={classNames('pos-r', { 'mb-0': !emailValid })}
              inputClassName="size-16"
              labelClassName="size-12 p-0"
              onChange={resetFieldValidation}
              resetTouchOnChange
              ref={field => fieldRef(FORM_FIELDS.email, field)}
              autoComplete="email"
            />
          </FormGroup>
          <FormErrorMessage id={`${FORM_FIELDS.email}-errorMessage`} message={validationErrors[FORM_FIELDS.email]} />
        </>
      )}
      {!isUpdateMode && !isDeactivateMode && activationFormCheckbox}
      <ActivationFormButton cta={ctaText} />
    </Form>
  );
}

ActivationFormWrapperUI.propTypes = {
  validationErrors: PropTypes.objectOf(PropTypes.string),
  validate: PropTypes.func.isRequired,
  fields: PropTypes.shape({}).isRequired,
  resetFieldValidation: PropTypes.func,
  fieldRef: PropTypes.func.isRequired,
  dealer: PropTypes.shape({}),
  location: PropTypes.shape({}),
  dataTrackingParent: PropTypes.string,
  submitCallback: PropTypes.func.isRequired,
  metadata: PropTypes.shape({}).isRequired,
  mode: PropTypes.string,
};

ActivationFormWrapperUI.defaultProps = {
  validationErrors: {},
  resetFieldValidation: null,
  dealer: {},
  location: {},
  setIsValid: noop,
  dataTrackingParent: '',
  mode: '',
};

export const ActivationFormWrapper = formValidation(ActivationFormWrapperUI, validators);
