import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { isEmpty, noop } from 'lodash';
/* Models */
import { CmsEntities } from 'client/data/models/cms';
/* Utils */
import { EventToolbox } from 'client/utils/event-toolbox';
/* Constants */
import {
  ACTIVATED_FORM_MODES,
  DEALER_DEACTIVATE_FORM,
  DEALER_UPDATE_FORM,
  UPDATE_MODE,
  DEACTIVATE_MODE,
} from 'site-modules/microsite/dealers/constants/digital-checkout';
import { DIGITAL_CHECKOUT_EVENTS } from 'site-modules/shared/constants/financing/digital-checkout-events';
/* Components */
import { SuccessMessage } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/success-message/success-message';
import { ActivationFormWrapper } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-wrapper/activation-form-wrapper';
import { ActivationFormDealerInfo } from 'client/site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activation-form/activation-form-dealer-info/activation-form-dealer-info';
import { ToggleButton } from 'site-modules/shared/components/inventory/toggle-button/toggle-button';
import { ToggleButtonGroup } from 'site-modules/shared/components/inventory/toggle-button-group/toggle-button-group';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

export const ActivationFormEnrolled = ({ entry, dealer, location, handleSubmit, showSuccess, isDeactivated }) => {
  const [isDeactivationWidgetViewFired, setIsDeactivationWidgetViewFired] = useState(false);
  const [activeMode, setActiveMode] = useState(UPDATE_MODE);
  const metadata = entry.getAllMetadata();

  const handleButtonChange = useCallback((_, value) => {
    setActiveMode(value);
    EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.MANAGE_FORM_TOGGLE, {
      value,
      creativeId: value === UPDATE_MODE ? DEALER_UPDATE_FORM : DEALER_DEACTIVATE_FORM,
    });
  }, []);

  useEffect(() => {
    if (activeMode === DEACTIVATE_MODE && !isDeactivationWidgetViewFired) {
      EventToolbox.fireCustomEvent(DIGITAL_CHECKOUT_EVENTS.WIDGET_VIEW, {
        creativeId: DEALER_DEACTIVATE_FORM,
      });
      setIsDeactivationWidgetViewFired(true);
    }
  }, [activeMode, isDeactivationWidgetViewFired]);

  if (isEmpty(metadata)) return null;

  const { formHeader, learnMoreCaramelLink } = metadata;
  const entries = entry.children();
  const currentEntry = entries.find(item => item.id === activeMode.toLowerCase());
  const entryMetadata = currentEntry.getAllMetadata();
  const { thankYouHeader, thankYouText, surveyThankYouText, surveyHeader } = entryMetadata;

  const isUpdateMode = activeMode === UPDATE_MODE;

  const submitCallback = async fields => {
    await handleSubmit({
      trackingData: {
        value: activeMode,
        subactionName: `submit_${activeMode.toLowerCase()}`,
        creativeId: isUpdateMode ? DEALER_UPDATE_FORM : DEALER_DEACTIVATE_FORM,
      },
      mode: activeMode,
      fields,
    });
  };

  return (
    <Row className="px-0_5 px-md-0">
      <Col xs={12} md={5} className="pe-md-1_25">
        <ActivationFormDealerInfo dealer={dealer} isDeactivated={isDeactivated} />
      </Col>
      <Col xs={12} md={7} className="ps-md-1_25">
        <div
          className={classNames('text-white text-center digital-checkout-activation-form', {
            'd-flex align-items-center': showSuccess,
            'px-1_5 px-md-1_25 py-2_5 py-md-1_25': showSuccess && isUpdateMode,
            'p-1_25': !showSuccess,
            'p-1_25 p-md-2_5': showSuccess && !isUpdateMode,
          })}
        >
          {showSuccess ? (
            <SuccessMessage
              thankYouHeader={thankYouHeader}
              thankYouText={thankYouText}
              surveyThankYouText={surveyThankYouText}
              surveyHeader={surveyHeader}
              showSurvey={!isUpdateMode}
              surveyOptions={currentEntry.children()}
            />
          ) : (
            <>
              <h2 className="size-24 text-cool-gray-10 mb-1_5">{formHeader}</h2>

              <ToggleButtonGroup
                onChange={handleButtonChange}
                value={activeMode}
                className="mb-1 mx-auto bg-cool-gray-50 toggle-button-wrapper"
                inactiveClassName="inactive-state bg-cool-gray-50 text-cool-gray-80 fw-medium"
                activeClassName="active-state bg-white text-cool-gray-50 fw-bold"
              >
                {ACTIVATED_FORM_MODES.map(mode => (
                  <ToggleButton key={`mode_${mode}`} value={mode}>
                    {mode}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              <ActivationFormWrapper
                dealer={dealer}
                location={location}
                dataTrackingParent={DEALER_UPDATE_FORM}
                submitCallback={submitCallback}
                metadata={entryMetadata}
                mode={activeMode}
              />
            </>
          )}
        </div>
        <div data-tracking-parent={isUpdateMode ? DEALER_UPDATE_FORM : DEALER_DEACTIVATE_FORM}>
          <ContentFragment classes="text-center size-20 text-white mt-1_5">{learnMoreCaramelLink}</ContentFragment>
        </div>
      </Col>
    </Row>
  );
};

ActivationFormEnrolled.propTypes = {
  entry: CmsEntities.Content.isRequired,
  dealer: PropTypes.shape({}),
  location: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  showSuccess: PropTypes.bool,
  isDeactivated: PropTypes.bool,
};

ActivationFormEnrolled.defaultProps = {
  dealer: {},
  location: {},
  handleSubmit: noop,
  showSuccess: false,
  isDeactivated: false,
};
