import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { IMaskInput } from 'react-imask';
import { FormField } from 'site-modules/shared/components/form-field/form-field';

/**
 This is base form field for venom.
 It is a wrapper to input field from https://www.npmjs.com/package/react-imask
 it can be used for masked inputs such as phone, email etc.
 */

export class FormFieldMaskV2 extends Component {
  static propTypes = {
    ...FormField.propTypes,
    mask: PropTypes.shape({
      pattern: PropTypes.string.isRequired,
      toValue: PropTypes.bool,
      placeholder: PropTypes.string,
      mask: PropTypes.func,
    }).isRequired,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    ...FormField.defaultProps,
    defaultValue: '',
  };

  state = {
    value: this.props.defaultValue,
  };

  componentDidUpdate(prevProps) {
    const { defaultValue } = this.props;
    if (defaultValue !== prevProps.defaultValue) {
      this.updateMaskState(defaultValue);
    }
  }

  /**
   * @param _ - do NOT use this. Instead, the value is determined in the function body based on the 'mask.toValue' prop.
   * @param {Object} inputRef - inputRef
   */
  onChange = (_, inputRef) => {
    const { mask, onChange } = this.props;

    // Determine value here, because we pass 'unmask' as false to IMaskInput always (see render logic).
    const value = mask?.toValue ? inputRef.unmaskedValue : inputRef.value;

    this.updateMaskState(value);

    /**
     * align cursor after first symbol required to fix bug when after applying mask cursor shifting
     */
    if (value.length === 1) inputRef.alignCursor();

    if (typeof onChange === 'function') {
      onChange(value, inputRef);
    }
  };

  setValue = value => {
    this.updateMaskState(value);
    return value;
  };

  updateMaskState = value => {
    this.setState({
      value,
    });
  };

  value = () => this.state.value;

  reset = () => {
    this.setState({
      value: '',
    });
  };

  focus = () => {
    this.inputRef.current.focus();
  };

  touch = () => {
    this.formFieldRef.current.touch();
  };

  // ref for FormField element
  formFieldRef = React.createRef();
  // ref for input element
  inputRef = React.createRef();
  // ref for Imask instance, allow using methods from mask,
  // subscribe on events, change dynamically mask, control options etc...
  maskRef = React.createRef();

  render() {
    const { onChange, value, defaultValue, tag, mask, ...props } = this.props;
    const { value: fieldVal } = this.state;
    const hasMaskType = !!mask.mask;

    return (
      <FormField
        {...props}
        inputTagRef={this.maskRef}
        inputRef={this.inputRef}
        ref={this.formFieldRef}
        onAccept={this.onChange}
        tag={IMaskInput}
        // IMPORTANT: 'unmask' prop MUST be false, otherwise 'skipInvalid' feature may break. See https://github.com/uNmAnNeR/imaskjs/issues/1086
        unmask={false}
        lazy={!fieldVal.length}
        value={fieldVal}
        {...(hasMaskType ? { ...mask } : { mask: mask.pattern, placeholder: mask.placeholder })}
      />
    );
  }
}
