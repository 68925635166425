import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { CmsEntities } from 'client/data/models/cms';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ClippedContainer } from 'client/site-modules/shared/components/clipped-container/clipped-container';
import { STATISTICS_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';
import { TrackingConstant } from 'client/tracking/constant';
import { SlideRightOnScroll } from 'site-modules/shared/components/animated/slide-right';
import { SlideLeftOnScroll } from 'site-modules/shared/components/animated/slide-left';
import { GrowOnScroll } from 'site-modules/shared/components/animated/grow';

import './dealer-center-statistics.scss';

const NumberCircle = ({ numberBig, numberSmall }) => (
  <GrowOnScroll className="number-circle">
    <div className="number-circle-ring rounded-circle pos-r d-flex justify-content-center align-items-center">
      <div>
        <span className="number-circle-big fw-bold">{numberBig}</span>
        {numberSmall && <span className="number-circle-small">{numberSmall}</span>}
      </div>
    </div>
  </GrowOnScroll>
);

NumberCircle.propTypes = {
  numberBig: PropTypes.string.isRequired,
  numberSmall: PropTypes.string,
};

NumberCircle.defaultProps = {
  numberSmall: '',
};

const WAVY_PATH_TOP = {
  name: 'wavy-path-top',
  path: 'M0,1 C0.514,0.947,1,1,1,1 V0.03 C0.631,-0.01,0.412,-0.01,0,0.03 V1',
};

const WAVY_PATH_BOTTOM = {
  name: 'wavy-path-bottom',
  path: 'M0,0 C0.514,0.053,1,0,1,0 V0.97 C0.631,1,0.412,1,0,0.97 V0',
};

export const DealerCenterStatisticsUI = ({ entry, isMobile }) => {
  const containerRef = useRef(null);

  const metadata = entry.getAllMetadata();
  const isEmptyMetadata = isEmpty(metadata);

  const { headerText, subheaderText, disclaimerText, numberBig, numberSmall, numberAlign } = metadata;

  useScrollIntoViewTracking({
    eventData: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.SUBACTION_STATISTICS_MODULE_DISPLAYED,
        creative_id: STATISTICS_CREATIVE_ID,
        value: `${numberBig}${numberSmall || ''}`,
      },
    },
    additionalConditions: !isEmptyMetadata,
    ref: containerRef,
  });

  if (isEmptyMetadata) return null;

  const isNumberLeftAligned = numberAlign === 'left';
  const isNumberRightAligned = numberAlign === 'right';

  const ContentWrapperComponent = isNumberRightAligned ? SlideRightOnScroll : SlideLeftOnScroll;

  return (
    <div
      className={classNames(
        'dealer-center-statistics d-flex align-items-center justify-content-center text-cool-gray-100 pos-r',
        {
          'dealer-center-statistics--number-left': isNumberLeftAligned,
          'dealer-center-statistics--mobile flex-column': isMobile,
          'px-2': !isMobile,
        }
      )}
      ref={containerRef}
      data-tracking-parent={STATISTICS_CREATIVE_ID}
    >
      {(isNumberLeftAligned || isMobile) && <NumberCircle numberBig={numberBig} numberSmall={numberSmall} />}
      <div
        className={classNames('text-container pos-r h-100', {
          'me-2': isNumberRightAligned && !isMobile,
          'ms-2': isNumberLeftAligned && !isMobile,
          'w-100': isMobile,
        })}
      >
        {isMobile ? (
          <ContentWrapperComponent>
            <ClippedContainer mask={WAVY_PATH_TOP} containerClasses="top-layer w-100 h-100 pos-a" />
            <ClippedContainer mask={WAVY_PATH_BOTTOM} containerClasses="bottom-layer w-100 h-100 pos-a" />
            <div className="pt-3_5 pb-2_5 px-1_75">
              <ContentFragment componentToUse="h2" classes="size-30 fw-bold text-cool-gray-100">
                {`${headerText} ${subheaderText}`}
              </ContentFragment>
              {disclaimerText && (
                <ContentFragment componentToUse="p" classes="size-16 mt-1">
                  {disclaimerText}
                </ContentFragment>
              )}
            </div>
          </ContentWrapperComponent>
        ) : (
          <ContentWrapperComponent>
            <h2 className="size-36 text-cool-gray-100 fw-medium">{headerText}</h2>
            <ContentFragment componentToUse="p" classes="size-48 fw-bold">
              {subheaderText}
            </ContentFragment>
            {disclaimerText && (
              <ContentFragment componentToUse="p" classes="size-16 mt-1">
                {disclaimerText}
              </ContentFragment>
            )}
          </ContentWrapperComponent>
        )}
      </div>
      {isNumberRightAligned && !isMobile && <NumberCircle numberBig={numberBig} numberSmall={numberSmall} />}
    </div>
  );
};

DealerCenterStatisticsUI.propTypes = {
  entry: CmsEntities.Content.isRequired,
  isMobile: PropTypes.bool,
};

DealerCenterStatisticsUI.defaultProps = {
  isMobile: false,
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DealerCenterStatistics = connect(mapStateToProps)(DealerCenterStatisticsUI);
