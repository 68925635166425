import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { breakpoints } from 'client/utils/bootstrap';
import { connect } from 'react-redux';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { isEmpty } from 'lodash';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';
import { TrackingConstant } from 'client/tracking/constant';
import { DealerCenterMedia } from 'site-modules/microsite/dealers/components/dealer-center-media/dealer-center-media';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';
import { TESTIMONIALS_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import { ClippedContainer } from 'site-modules/shared/components/clipped-container/clipped-container';
import { randomInt } from 'client/utils/seed-randomizers';
import { Carousel } from 'site-modules/shared/components/carousel/carousel';

import './dealer-center-testimonial-module.scss';

const CONCAVE_CONTAINER = {
  name: 'concave-container',
  path: 'M0,0 H1 L.9,.85 C.88,1,.7,1,.6,1 H0z',
};

const RESPONSIVE_OPTIONS = [{ breakpoint: breakpoints.sm, settings: { slidesToShow: 1.1, slidesToScroll: 1 } }];

export function DealerCenterTestimonialCard({ quotedName, imgUrl, testimonialText, isMobile }) {
  const uniqIdRef = useRef(randomInt());
  const getInnerMask = useCallback(
    maskObject => ({
      ...maskObject,
      name: `${maskObject.name}-${uniqIdRef.current}`,
    }),
    []
  );
  const uniqTaperedContentBoxMask = useMemo(() => getInnerMask(CONCAVE_CONTAINER), [getInnerMask]);

  return (
    <div className="dealer-center-testimonial-card d-lg-flex justify-content-lg-center me-1">
      <ClippedContainer containerClasses="img-container" mask={uniqTaperedContentBoxMask}>
        <DealerCenterMedia imageSrc={imgUrl} imageClass="w-100" />
      </ClippedContainer>
      <div className="quote-container d-flex flex-column justify-content-end justify-content-lg-center align-items-end text-end text-cool-gray-10">
        <div className="quote-and-attribution d-flex flex-column justify-content-center align-items-lg-end">
          <div className={classnames('fw-bold mb-1_5', { 'size-36': !isMobile, 'size-24': isMobile })}>
            &ldquo;{testimonialText}&rdquo;
          </div>
          <div className="text-underline">- {quotedName}</div>
        </div>
      </div>
    </div>
  );
}

DealerCenterTestimonialCard.propTypes = {
  quotedName: PropTypes.string,
  imgUrl: PropTypes.string,
  testimonialText: PropTypes.string,
  isMobile: PropTypes.bool,
};

DealerCenterTestimonialCard.defaultProps = {
  quotedName: '',
  imgUrl: '',
  testimonialText: '',
  isMobile: false,
};

export const DealerCenterTestimonialModuleUI = ({ entry, isMobile }) => {
  const children = entry.children();
  const metadata = entry.getAllMetadata();
  const { headerText } = metadata;
  let cardContentLength = 0;
  const isEmptyMetadata = isEmpty(metadata) || isEmpty(children);

  const ref = useRef();

  useScrollIntoViewTracking({
    eventData: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.SUBACTION_TESTIMONIALS_DISPLAYED,
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        creative_id: TESTIMONIALS_CREATIVE_ID,
        value: headerText,
      },
    },
    ref,
    additionalConditions: !isEmptyMetadata,
  });

  const cardsContent = useMemo(
    () =>
      children.map(childEntry => {
        const { creditText: quotedName, itemImage: imgUrl, testimonialText } = childEntry.getAllMetadata();
        cardContentLength = Math.max(cardContentLength, testimonialText.length);
        return (
          <DealerCenterTestimonialCard
            key={childEntry.id}
            quotedName={quotedName}
            imgUrl={imgUrl}
            testimonialText={testimonialText}
            isMobile={isMobile}
          />
        );
      }),
    [children, isMobile]
  );

  if (isEmptyMetadata) return null;

  return (
    <div
      className="dealer-center-testimonial-module ps-lg-2"
      data-tracking-parent={TESTIMONIALS_CREATIVE_ID}
      ref={ref}
      style={{ '--cards-content-length': cardContentLength }}
    >
      <FadeInSlideUpOnScroll>
        <ContentFragment componentToUse={'h2'} classes="text-center text-white fw-bold size-36 mb-1_25">
          {headerText}
        </ContentFragment>
      </FadeInSlideUpOnScroll>
      <div className="testimonial-cards-container w-100 px-0_5 pxlg-2 px-xl-0">
        <Carousel
          swipe
          arrows={false}
          infinite={false}
          draggable
          responsive={RESPONSIVE_OPTIONS}
          dots
          dotsClass="slick-dots white"
          speed={500}
          cssEase="ease-in-out"
          className="testimonial-carousel"
          trackSwipeEvent
          customEventData={{
            creative_id: TESTIMONIALS_CREATIVE_ID,
            action_category: TrackingConstant.USER_ACTION_CATEGORY,
            action_cause: 'link_click',
            action_name: TrackingConstant.ACTION_VIEW_CONTENT,
            subaction_name: TrackingConstant.SUBACTION_VIEW_TESTIMONIAL,
          }}
          slidesToShow={1.5}
          scrollable
        >
          {cardsContent}
        </Carousel>
      </div>
    </div>
  );
};

DealerCenterTestimonialModuleUI.propTypes = {
  entry: CmsEntities.Content,
  isMobile: PropTypes.bool,
};

DealerCenterTestimonialModuleUI.defaultProps = {
  entry: DEFAULT_CONTENT,
  isMobile: false,
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DealerCenterTestimonialModule = connect(mapStateToProps)(DealerCenterTestimonialModuleUI);
