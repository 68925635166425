import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';
import {
  BENEFITS_BACKGROUND,
  BENEFITS_BACKGROUND_MOBILE,
} from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-backgrounds';
import { getCtaText } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/utils/digital-checkout-helper';
import { DigitalCheckoutActivateButton } from 'site-modules/microsite/dealers/components/dealer-center-digital-checkout/digital-checkout-activate-button/digital-checkout-activate-button';

import './digital-checkout-benefits.scss';

export const DigitalCheckoutBenefitsUI = ({ entry, isMobile, dealerStatus }) => {
  const metadata = entry.getAllMetadata();

  if (isEmpty(metadata)) return null;

  const { imageSrc, header, ctaText, activatedCtaText, invalidCtaText } = metadata;
  const bullets = entry.children();

  return (
    <div className="benefits-wrapper pos-r" data-tracking-parent="caramel-benefits">
      <figure className="pos-a w-100 m-0 background">
        {isMobile ? BENEFITS_BACKGROUND_MOBILE : BENEFITS_BACKGROUND}
      </figure>

      <Container className="pt-3_5">
        <h2 className="px-0_5 px-md-0 text-white mt-md-1_5 mb-2 mb-md-2_5 size-36 text-center">{header}</h2>
        <Row className="d-flex justify-content-between mt-1_75">
          <Col xs={12} md={6}>
            <div className={classnames('image-container m-0', { 'pos-a': !isMobile, 'pos-r': isMobile })}>
              <img className="object-fit-cover w-100" src={imageSrc} alt="" aria-hidden />
            </div>
          </Col>

          <Col xs={12} md={6} className="px-1 px-md-0_5">
            <ul className="text-white list-unstyled p-0 mb-0_5 size-24">
              {bullets.map((bullet, index) => {
                const { title, description } = bullet.getAllMetadata();
                return (
                  <li className="py-1 d-flex" key={`what-you-enable-item_${index + 1}`}>
                    <figure className="ps-0 ps-md-1 pe-1">
                      <svg
                        style={{ width: '24px', height: '28px' }}
                        viewBox="0 0 24 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect y={3.62109} rx={12} style={{ width: '24px', height: '24px' }} fill="#007C8C" />
                        <mask
                          id="mask0_4531_166804"
                          maskUnits="userSpaceOnUse"
                          x={0}
                          y={3}
                          style={{ width: '24px', height: '25px', maskType: 'luminance' }}
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0 15.6211C0 8.99409 5.373 3.62109 12 3.62109C18.627 3.62109 24 8.99409 24 15.6211C24 22.2481 18.627 27.6211 12 27.6211C5.373 27.6211 0 22.2481 0 15.6211ZM9.75003 21.6671L9.75075 21.668H9.74925L9.75003 21.6671ZM9.75003 21.6671L18.5618 12.059L17.4832 10.9805L9.74925 17.7305L7.07775 15.1055L5.53125 16.652L9.75003 21.6671Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_4531_166804)">
                          <rect x={-2} y={1.62109} style={{ width: '28px', height: '28px' }} rx={4} fill="#C2F8FF" />
                        </g>
                      </svg>
                    </figure>
                    <div>
                      <div className="size-24 fw-bold mb-0_5">{title}</div>
                      <div className="size-16">{description}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <DigitalCheckoutActivateButton
              cta={getCtaText({ dealerStatus, ctaText, activatedCtaText, invalidCtaText })}
              classNames="mt-0_5 mx-auto mx-md-0 ms-md-3_5 d-block"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

DigitalCheckoutBenefitsUI.propTypes = {
  entry: CmsEntities.Content.isRequired,
  isMobile: PropTypes.bool,
  dealerStatus: PropTypes.string,
};

DigitalCheckoutBenefitsUI.defaultProps = {
  isMobile: false,
  dealerStatus: '',
};

export const mapStateToProps = ({ mobile }) => ({
  isMobile: mobile,
});

export const DigitalCheckoutBenefits = connect(mapStateToProps)(DigitalCheckoutBenefitsUI);
