import React, { useRef } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import classnames from 'classnames';
import { TrackingConstant } from 'client/tracking/constant';
import { isEmpty } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { DealerCenterButton } from 'site-modules/microsite/dealers/components/dealer-center-button/dealer-center-button';
import { DealerCenterMedia } from 'site-modules/microsite/dealers/components/dealer-center-media/dealer-center-media';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';
import { useOnScreen } from 'client/site-modules/shared/hooks/use-on-screen';
import { SlideLeftOnCondition } from 'site-modules/shared/components/animated/slide-left';
import { SlideRightOnCondition } from 'site-modules/shared/components/animated/slide-right';

import './dealer-center-side-by-side-module.scss';

export function DealerCenterSideBySideModuleCard({ entry }) {
  const {
    headerText,
    subheaderText,
    text,
    ctaDisplay,
    ctaLink,
    ctaClass,
    ctaText,
    ctaNewTab,
    image: imageLink,
    imageAlign,
    videoURL,
  } = entry.getAllMetadata();
  const ref = useRef();
  const [isVisible] = useOnScreen(ref);

  const creativeId = `dealercenter-side-image-${imageAlign}`;

  useScrollIntoViewTracking({
    eventData: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.SIDE_BY_SIDE_MODULE_DISPLAYED,
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        creative_id: creativeId,
        value: headerText,
      },
    },
    ref,
  });

  const isMediaLeftAligned = imageAlign === 'left';
  const isMediaRightAligned = imageAlign === 'right';
  const hasCta = ctaDisplay === 'true';

  const MediaWrapperComponent = isMediaLeftAligned ? SlideRightOnCondition : SlideLeftOnCondition;
  const ContentWrapperComponent = isMediaLeftAligned ? SlideLeftOnCondition : SlideRightOnCondition;

  const media = (
    <Col xs={12} md={5} lg={6} className="media-container pos-r">
      <MediaWrapperComponent shouldAnimate={isVisible}>
        <DealerCenterMedia videoSrc={videoURL} imageSrc={imageLink} videoClass="card-media" imageClass="card-media" />
      </MediaWrapperComponent>
    </Col>
  );

  return (
    <div
      className={classnames(
        'dealer-center-side-by-side-module-card d-flex flex-column align-items-center justify-content-center d-md-block pos-r',
        isMediaLeftAligned ? 'is-left-to-right' : 'is-right-to-left'
      )}
      data-tracking-parent={creativeId}
      ref={ref}
    >
      <div className="content-container w-100 p-1_75 p-md-0 pos-r">
        <ContentWrapperComponent shouldAnimate={isVisible} className="content-container-background pos-a" />
        <Row>
          {isMediaLeftAligned && media}
          <Col xs={12} md={7} lg={6}>
            <ContentWrapperComponent shouldAnimate={isVisible}>
              <div
                className={classnames(
                  'content d-flex flex-column align-items-start h-100 mt-md-2 mt-xl-3',
                  isMediaLeftAligned ? 'me-md-3 ms-md-1_75 pt-0_5 pt-md-0' : 'me-md-auto mb-auto mb-md-0'
                )}
              >
                <h2 className="size-36 fw-bold text-cool-gray-10 mb-1_25">{headerText}</h2>
                {subheaderText && <h3 className="size-30 text-cool-gray-10 mb-1_25">{subheaderText}</h3>}
                <ContentFragment componentToUse="div" classes="card-text text-cool-gray-10 mb-2_5">
                  {text}
                </ContentFragment>
                {hasCta && (
                  <DealerCenterButton
                    href={ctaLink}
                    color="blue"
                    className={classnames('px-1 py-0_75 fw-bold mb-md-2', ctaClass)}
                    data-tracking-id="submit"
                    ctaText={ctaText}
                    target={ctaNewTab === 'true' ? '_blank' : '_self'}
                  />
                )}
              </div>
            </ContentWrapperComponent>
          </Col>
          {isMediaRightAligned && media}
        </Row>
      </div>
    </div>
  );
}

DealerCenterSideBySideModuleCard.propTypes = {
  entry: CmsEntities.Content,
};

DealerCenterSideBySideModuleCard.defaultProps = {
  entry: DEFAULT_CONTENT,
};

export function DealerCenterSideBySideModule({ entry }) {
  const children = entry.children();

  if (isEmpty(children)) return null;

  return (
    <div className="dealer-center-side-by-side-module d-flex justify-content-center">
      <svg width={0} height={0}>
        <defs>
          <clipPath id="side-by-side-tapered-content-box" clipPathUnits="objectBoundingBox">
            <path d="M0,0 L0.991,0 Q1,0,1,0.023 L0.945,0.823 Q0.934,1,0.871,1 L0,1 L0,0" />
          </clipPath>
        </defs>
      </svg>
      <div className="cards-container w-100 px-0_5 px-md-2 px-xl-0">
        {children.map(childEntry => (
          <DealerCenterSideBySideModuleCard key={childEntry.id} entry={childEntry} />
        ))}
      </div>
    </div>
  );
}

DealerCenterSideBySideModule.propTypes = {
  entry: CmsEntities.Content,
};

DealerCenterSideBySideModule.defaultProps = {
  entry: DEFAULT_CONTENT,
};
