import React, { useRef } from 'react';
import Row from 'reactstrap/lib/Row';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { CmsEntities } from 'client/data/models/cms';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { TrackingConstant } from 'client/tracking/constant';
import { FEATURE_GRID_CREATIVE_ID } from 'site-modules/microsite/dealers/constants/page';
import { useScrollIntoViewTracking } from 'site-modules/shared/hooks/use-scroll-into-view-tracking';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { FadeInSlideUpOnScroll } from 'site-modules/shared/components/animated/fade-in-slide-up';
import { DealerCenterCarLogo } from 'site-modules/microsite/dealers/components/dealer-center-car-logo/dealer-center-car-logo';
import { DealerCenterFeatureGridItem } from './dealer-center-feature-grid-item';

import './dealer-center-feature-grid.scss';

const ITEM_COUNT = 3;

export const DealerCenterFeatureGrid = ({ entry }) => {
  const metadata = entry.getAllMetadata();
  const children = entry.children();

  const { 'header-text': headerText } = metadata || {};

  const shouldRender = !!headerText && !isEmpty(children) && children.length >= ITEM_COUNT;

  const containerRef = useRef(null);
  useScrollIntoViewTracking({
    eventData: {
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_cause: TrackingConstant.ACTION_CAUSE_SCROLL,
        action_name: TrackingConstant.ACTION_SHOW_CONTENT,
        subaction_name: TrackingConstant.SUBACTION_FEATURES_GRID_MODULE_DISPLAYED,
        creative_id: FEATURE_GRID_CREATIVE_ID,
        value: headerText,
      },
    },
    additionalConditions: shouldRender,
    ref: containerRef,
  });

  if (!shouldRender) {
    return null;
  }

  const childrenToRender = children.slice(0, ITEM_COUNT);

  return (
    <div
      className="dealer-center-feature-grid d-flex flex-column align-items-center pos-r"
      data-tracking-parent={FEATURE_GRID_CREATIVE_ID}
      ref={containerRef}
    >
      <div
        className="feature-grid-background"
        style={{
          backgroundImage: `url('${getStaticImageUrl('/img/dealers-microsite/feature-grid-bg.png?imbypass=on')}')`,
        }}
      />
      <FadeInSlideUpOnScroll>
        <DealerCenterCarLogo iconContainerClassName="pb-1_25 px-1_25" />
      </FadeInSlideUpOnScroll>
      <FadeInSlideUpOnScroll>
        <ContentFragment classes="header text-white text-center fw-bold size-36 mb-2">{headerText}</ContentFragment>
      </FadeInSlideUpOnScroll>
      <FadeInSlideUpOnScroll>
        <Row className="item-container justify-content-center flex-md-nowrap" noGutters>
          {childrenToRender.map((childEntry, index) => (
            <DealerCenterFeatureGridItem
              key={childEntry.id}
              entry={childEntry}
              className={classnames({
                'mb-1 mb-md-0 me-md-1': index !== childrenToRender.length - 1,
              })}
            />
          ))}
        </Row>
      </FadeInSlideUpOnScroll>
    </div>
  );
};

DealerCenterFeatureGrid.propTypes = {
  entry: CmsEntities.Content.isRequired,
};
