import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { LINKS } from 'site-modules/shared/components/footer/footer-data';

import './dealer-center-footer.scss';

export const makeHrefAbsolute = href => {
  if (href.startsWith('http') || href.startsWith('//')) {
    return href;
  }
  const normalizedHref = href.startsWith('/') ? href : `/${href}`;
  return `//www.edmunds.com${normalizedHref}`;
};

export const DealerCenterFooter = () => (
  <div
    className="container-fluid dealer-center-footer py-1_25 py-md-2_5 text-cool-gray-100"
    data-tracking-parent="dealercenter-footer"
  >
    <div className="container" role="contentinfo">
      <Row className="footer-links text-center">
        <Col xs={12} className="px-0">
          <ul className="list-wrapping list-unstyled size-12 mb-0">
            {LINKS.map(({ href, text }) => (
              <li key={text} className="footer-link-item d-inline-block px-0_75 mb-0_75">
                <a href={makeHrefAbsolute(href)}>{text}</a>
              </li>
            ))}
          </ul>
        </Col>
      </Row>

      <Row className="copyright text-center">
        <Col xs={12}>
          <div className="size-12 mt-0_5">
            <a href="//www.edmunds.com/" className="ms-0_25 ms-md-0_5 pt-0_5 pt-md-0">
              &copy;Edmunds.com, Inc.
            </a>
            , a wholly owned subsidiary of CarMax, Inc. By using edmunds.com, you consent to the monitoring and storing
            of your interactions with the website, including by an Edmunds vendor, for use in improving and
            personalizing our services. See our{' '}
            <a href="https://www.edmunds.com/about/privacy.html">Privacy Statement</a> for details.
          </div>
        </Col>
      </Row>
    </div>
  </div>
);
