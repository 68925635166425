import React, { Fragment } from 'react';
import { NavItem, NavLink, Button } from 'reactstrap';
import classNames from 'classnames';

import { Link } from 'site-modules/shared/components/link/link';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

const getNavBarData = entriesToRender =>
  !!entriesToRender && entriesToRender.childEntries
    ? entriesToRender.childEntries.filter(entry => entry.id === 'navigation').pop().childEntries
    : [];

const getNavChildEl = (mainNavEntry, onClick) => (
  <NavItem key={mainNavEntry.id}>
    <NavLink
      className="heading-5 px-0_5 py-0_25 fw-normal"
      href="#"
      onClick={onClick}
      data-tracking-id="nav_menu_item"
      data-tracking-value={mainNavEntry.contentMetadata.title}
    >
      <span id={mainNavEntry.id} className="d-inline-block py-1_25 px-0_5">
        {mainNavEntry.contentMetadata.title}
        <hr />
      </span>
    </NavLink>
  </NavItem>
);

const getNavEntryChildren = (mainNavEntry, isSmall = false) => (
  <div className={classNames('collapse-container', { 'd-flex': !isSmall })}>
    {mainNavEntry.childEntries.map((subNavEntry, idx) => (
      <div
        className={classNames('text-yellow-100', {
          'py-0': isSmall,
          'collapse-content': !isSmall,
          'my-2': !isSmall,
          'px-1_5': !isSmall,
        })}
        key={subNavEntry.id}
      >
        {subNavEntry.contentMetadata.title && (
          <h4 className={classNames('mb-1 heading-4 text-yellow-100', { 'fw-bold': isSmall })}>
            {subNavEntry.contentMetadata.title}
          </h4>
        )}
        {subNavEntry.links &&
          subNavEntry.links.map(subNavLink => (
            <div key={subNavLink.title} className={classNames({ 'my-1': isSmall, 'my-0_75': !isSmall })}>
              <Link to={subNavLink.href} data-tracking-id="nav_menu_item" data-tracking-value={subNavLink.title}>
                {subNavLink.title}
              </Link>
            </div>
          ))}
        {isSmall && <hr className={classNames({ 'mb-1': idx + 1 < mainNavEntry.childEntries.length })} />}
      </div>
    ))}
  </div>
);

const getNavChildEntries = (mainNavEntry, onClick, isSmall = false) => ({
  id: mainNavEntry.id,
  el: isSmall ? getNavEntryChildren(mainNavEntry, isSmall) : getNavChildEl(mainNavEntry, onClick),
  children: isSmall ? null : getNavEntryChildren(mainNavEntry, isSmall),
});

const getNavButtonEl = mainNavEntry => (
  <NavItem key={mainNavEntry.id}>
    <Button
      className="heading-5 ms-lg-0_5 ms-0 my-lg-0 my-0_5 px-1 py-0_5"
      color="secondary"
      href={mainNavEntry.contentMetadata.url}
      data-tracking-id="nav_menu_item"
      data-tracking-value={mainNavEntry.contentMetadata.title}
    >
      <ContentFragment componentToUse="span" id={mainNavEntry.id}>
        {mainNavEntry.contentMetadata.title}
      </ContentFragment>
    </Button>
  </NavItem>
);

const getNavButton = mainNavEntry => ({
  id: mainNavEntry.id,
  el: getNavButtonEl(mainNavEntry),
});

const getNavEl = (mainNavEntry, isSmall = false) => (
  <Fragment>
    <NavItem key={mainNavEntry.id}>
      <NavLink
        className={classNames({
          'heading-5': !isSmall,
          'py-0_25': !isSmall,
          'px-0_5': !isSmall,
          'fw-normal': !isSmall,
          'heading-4': isSmall,
          'px-0': isSmall,
          'py-1': isSmall,
          'fw-bold': isSmall,
        })}
        href={mainNavEntry.contentMetadata.url}
        data-tracking-id="nav_menu_item"
        data-tracking-value={mainNavEntry.contentMetadata.title}
      >
        {isSmall ? (
          <ContentFragment id={mainNavEntry.id}>{mainNavEntry.contentMetadata.title}</ContentFragment>
        ) : (
          <span id={mainNavEntry.id} className="d-inline-block py-1_25 px-0_5">
            {mainNavEntry.contentMetadata.title}
            <hr />
          </span>
        )}
      </NavLink>
    </NavItem>
    {isSmall && <hr />}
  </Fragment>
);

const getNavEntry = (mainNavEntry, isSmall = false) => ({
  id: mainNavEntry.id,
  el: getNavEl(mainNavEntry, isSmall),
});

export const renderNavEntries = (entriesToRender, onClick) => {
  const navBarData = getNavBarData(entriesToRender);
  if (!navBarData.length) {
    return [{ el: null }];
  }

  return navBarData
    .reduce((entries, mainNavEntry) => {
      if ('childEntries' in mainNavEntry) {
        entries.push(getNavChildEntries(mainNavEntry, onClick));
      } else if ('button' in mainNavEntry.contentMetadata && mainNavEntry.contentMetadata.button === 'true') {
        entries.push(getNavButton(mainNavEntry));
      } else {
        entries.push(getNavEntry(mainNavEntry));
      }
      return entries;
    }, [])
    .slice();
};

export const getContactData = globalNavData => {
  const contactEntry =
    !!globalNavData && globalNavData.childEntries && globalNavData.childEntries.find(entry => entry.id === 'contact');
  return Object.assign(
    { title: 'Contact Us', url: '/industry/contact-us.html' },
    (contactEntry && contactEntry.contentMetadata) || {}
  );
};

export function renderNavBarEntries(entriesToRender, onClick) {
  const navBarData = getNavBarData(entriesToRender);
  if (!navBarData.length) {
    return [{ el: null }];
  }

  return navBarData
    .reduce((entries, mainNavEntry) => {
      if ('childEntries' in mainNavEntry) {
        entries.push(getNavChildEntries(mainNavEntry, onClick, true));
      } else {
        entries.push(getNavEntry(mainNavEntry, true));
      }
      return entries;
    }, [])
    .slice();
}
