export const CARAMEL_NEGOTIATION = {
  text: 'Hassle-free negotiation',
  icon: '/icons/finger-selecting.svg',
  width: 30,
  height: 30,
};
export const CARAMEL_FINANCING = {
  text: 'No-pressure financing',
  icon: '/icons/percent-in-hand.svg',
  width: 30,
  height: 30,
};
export const CARAMEL_DELIVERY = {
  text: 'Pickup or delivery',
  icon: '/icons/delivery-truck.svg',
  width: 38,
  height: 30,
};
export const CARAMEL_CHECKOUT = {
  text: 'Simple Online Checkout',
  icon: '/icons/finger-selecting.svg',
  width: 38,
  height: 30,
};

export const CARAMEL_ENTRY_POINT_DATA = {
  benefits: [CARAMEL_CHECKOUT, CARAMEL_FINANCING, CARAMEL_DELIVERY],
  cta: 'Start Purchase',
  header: 'Online Checkout and Delivery',
};

export const DEAL_IN_PROGRESS = 'in_progress';
export const DEAL_NOT_STARTED = 'not_started';
export const DEAL_COMPLETED = 'completed';
export const DEAL_INACTIVE = 'inactive';

export const DEAL_STATUS_PENDING = 'Pending Sale';
export const DEAL_STATUS_SOLD = 'Recently Sold';
export const CARAMEL_BENEFITS_LIST = [
  'Agree on a price with the dealer',
  'Get financing or insurance',
  'Digital vehicle inspection verified by 3rd party',
  'Online paperwork and secure payment',
  'Online title transfer and registration',
];

export const CARAMEL_SURVEY_LIST = [
  'Price negotiation',
  'Applying for financing',
  'Vehicle inspection',
  'Completing paperwork',
  'Vehicle registration and title transfer',
];
